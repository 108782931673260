angular.module('fingerink')
    .service('addUserTeamModal', function ($uibModal) {
        this.modalInstance = null;
        this.openModal = function () {
            this.modalInstance = $uibModal.open({
                backdrop: 'static',
                templateUrl: 'modals/addUserTeamModal.tpl.html',
                controller: 'addUserTeamModalCtrl',
                animation: false,
                size: 'xl'
            });

            return this.modalInstance;
        };

    })
    .controller('addUserTeamModalCtrl', function ($rootScope, $scope, $uibModalInstance, userService, session, $translate, swalService) {
        var that = session.get();
        $scope.close = () => $uibModalInstance.close();
        $scope.anyadiendouser = false;


        $scope.ok = function (a, b, c) {
            $scope.anyadiendouser = true;


            if (!a || !b || !c) {
                swal($translate.instant('Completa los campos'), $translate.instant('Debes introducir todos los campos'), 'info');
                return;
            }

            if (that.user.type != 'ADMIN') {
                return;
            }

            if (that.state.totalUsers <= that.state.totalUsersGastados) {
                swal($translate.instant("No te quedan usuarios"), $translate.instant("No puedes crear más usuarios, cambia tu plan o añade un bono de usuarios"), "warning");
                return;
            }
            swalService.requestSwal('¿Estás seguro?', 'Vas a crear un nuevo usuario que podrá acceder a la aplicación y enviar documentos para firmar', 'warning', () =>
                userService.createUser({
                    name: a,
                    email: c,
                    surname: b
                })).then(function (response) {
                    if (response.status === 200) {
                        swal($translate.instant('¡Correcto!'), $translate.instant('Se ha creado el nuevo usuario'), 'success');
                    } else {
                        swal($translate.instant('Algo ha ido mal'), $translate.instant('Ha habido un error creando al usuario'), 'error');
                    }
                }, function (response) {
                    if (response.data && response.data.errorsHTTP == "USER_REGISTERED") {
                        swal($translate.instant('¡Usuario ya registrado!'), $translate.instant("El email introducido ya tiene una cuenta de Fingerink asociada"), "error");
                    } else {
                        swal($translate.instant('Algo ha ido mal'), $translate.instant("Ha habido un error durante el registro"), "error");
                    }
                });
                $scope.anyadiendouser = false;
                $uibModalInstance.close();
        };


    });
